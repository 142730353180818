import React, { useState, useEffect } from "react"
import { useCookies } from 'react-cookie';
import Sidebar from '../SideBar';
import UserPage from "../UserPage";
import useAuthRedirect from "../Componentes/auth";
import Axios from 'axios';
import config from '../Componentes/config';
import Swal from 'sweetalert2';
import { validaFaenas } from '../Validations/TrabajadoresValidation';
import { registrarLog } from '../Componentes/logService';
import moment from 'moment';
import $ from "jquery";
import useCheckCookie from "../Componentes/cookieVal";

const cardImages = {
    DAFA: "/img/dafaLogo.png",
    FUGA: "/img/fugarLogo.png"
};


function Faenas() {
    useAuthRedirect();
    useCheckCookie();
    const [cookies] = useCookies(['selectedCard']);
    const [cookies2, setCookie2] = useCookies(['faenaActiva']);
    const imageUrl = cardImages[cookies.selectedCard]
    const idEmpresa = cookies.idEmpresa;
    const [isComponentsCollapsed, setIsComponentsCollapsed] = useState(true);
    const [isUtilitiesCollapsed, setIsUtilitiesCollapsed] = useState(true);
    const [isInventario, setisIventatioCollapsed] = useState(true);
    const [isPrevencion, setisPrevencionCollapsed] = useState(true);
    const [isLogistica, setisLogisticaCollapsed] = useState(true);
    const toggleCollapse = (collapseSetter) => {
        collapseSetter(prevState => !prevState);
    };
    const getEmpresaName = (card) => {
        switch (card) {
            case 'FUGA':
                return 'FUGAR SERVICIOS INDUSTRIALES SPA';
            case 'DAFA':
                return 'DAFA FACILITY SERVICES SPA';
            default:
                return ''; // Retorna un string vacío o un valor por defecto si no coincide
        }
    };
    // Estados para el componente Trabajadores
    const [empresa, setEmpresa] = useState(getEmpresaName(cookies.selectedCard));
    const [nombreFaena, setnombreFaena] = useState("");
    const [codigoFaena, setcodigoFaena] = useState("");
    const [listadoFaenas, setlistadoFaenas] = useState([]);
    const RegistraFaena = async () => {
        const mensajeError = validaFaenas(nombreFaena, codigoFaena);

        if (mensajeError) {
            Swal.fire('Advertencia', mensajeError, 'warning');
            return; // Detiene la función si falla la validación
        }
        const datos = {
            nombreFaena: nombreFaena,
            codigoFaena: codigoFaena,
            empresa: idEmpresa
        };
        try {
            const response = await Axios.post(`${config.API_URL}/registraFaena`, datos, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            Swal.fire(
                '¡Éxito!',
                `La Faena ${nombreFaena} se ha registrado exitosamente`,
                'success'
            );
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Prevención',
                Detalle: `Registra Faena: ${nombreFaena} codigo:${codigoFaena}`,
                Estado: 'Exito',
            });
        } catch (error) {
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Prevención',
                Detalle: `Registra Faena: ${nombreFaena} error: ${error}`,
                Estado: 'Error',
            });
            Swal.fire(
                '¡Algo pasó!',
                'La Faena no ha podido registrarse!',
                'error'
            );
        }
    }

    const FaenaActiva = (idFaena, nombreFaena, codigoFaena) => {
        setCookie2("faenaActiva", nombreFaena, { path: "/" });
        setCookie2('idFaena', idFaena);
        setCookie2('codigoFaena', codigoFaena);
        // navigate('/home');
      };

    const cargaFaenas = (ID) => {
        Axios.get(`${config.API_URL}/cargaFaenas?idEmpresa=${idEmpresa}`)
            .then(res => {
                console.log(res.data); // Verifica la estructura de la respuesta
                if (Array.isArray(res.data)) {
                    setlistadoFaenas(res.data); // Asegúrate de que sea un array
                } else {
                    console.error('La respuesta no es un array:', res.data);
                    setlistadoFaenas([]); // Establece un array vacío si no es un array
                }
            })
            .catch(err => {
                console.error("Error al obtener los datos:", err);
            });
    }

    useEffect(() => {
        cargaFaenas(); // Llamamos a la función al montar el componente
    }, []);


    useEffect(() => {
        if (listadoFaenas.length > 0) {
            $("#miTabla").DataTable({
                destroy: true, // Para evitar inicializaciones duplicadas
                paging: true,
                searching: true,
                ordering: true,
                info: true,
                autoWidth: false,
                responsive: true,
                language: {
                    search: "Buscar:",
                    lengthMenu: "Mostrar _MENU_ registros",
                    info: "Mostrando _START_ a _END_ de _TOTAL_ registros",
                    infoEmpty: "No hay registros disponibles",
                    infoFiltered: "(filtrado de _MAX_ registros totales)",
                    loadingRecords: "Cargando...",
                    zeroRecords: "No se encontraron registros",
                    emptyTable: "No hay datos disponibles en la tabla",
                    paginate: {
                        first: "Primero",
                        previous: "Anterior",
                        next: "Siguiente",
                        last: "Último"
                    },
                    aria: {
                        sortAscending: ": Activar para ordenar de manera ascendente",
                        sortDescending: ": Activar para ordenar de manera descendente"
                    }
                }
            });
        }
    }, [listadoFaenas]);
    return (
        <body id="page-top">
            <div id="wrapper">
                <Sidebar
                    imageUrl={imageUrl}
                    isComponentsCollapsed={isComponentsCollapsed}
                    toggleCollapse={toggleCollapse}
                    setIsComponentsCollapsed={setIsComponentsCollapsed}
                    isUtilitiesCollapsed={isUtilitiesCollapsed}
                    setIsUtilitiesCollapsed={setIsUtilitiesCollapsed}
                    isInventario={isInventario}
                    setisIventatioCollapsed={setisIventatioCollapsed}
                    isPrevencion={isPrevencion}
                    setisPrevencionCollapsed={setisPrevencionCollapsed}
                    isLogistica={isLogistica}
                    setisLogisticaCollapsed={setisLogisticaCollapsed}
                />
                <div id="content-wrapper" class="d-flex flex-column">
                    <div id="content">
                        <UserPage cookies={cookies} />
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            <h6 class="m-0 font-weight-bold text-primary">Creación de Faenas para {empresa}</h6>
                                        </div>
                                        <div class="card-body">
                                            <div class="row g-3">
                                                <div class="col-6">
                                                    <div class="input-group">
                                                        <span class="input-group-text" id="basic-addon1">Nombre Faena</span>
                                                        <input type="text" class="form-control" placeholder="Ingrese nombre faena" value={nombreFaena} onChange={(event) => { setnombreFaena(event.target.value); }} />
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="input-group">
                                                        <span class="input-group-text" id="basic-addon1">Codigo Faena</span>
                                                        <input type="text" class="form-control" placeholder="8 digitos alfanumericos" value={codigoFaena} onChange={(event) => { setcodigoFaena(event.target.value); }} />
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <button className="btn btn-success" onClick={RegistraFaena}>Registrar</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            <h6 class="m-0 font-weight-bold text-primary">Listado de Faenas para {empresa}</h6>
                                        </div>
                                        <div class="card-body">
                                            <div class="row g-3">
                                            <h10 class="m-0 font-weight-bold text-secondary">Faena activa: {cookies2.faenaActiva}</h10>
                                                <table id="miTabla" className="display">
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Nombre Faena</th>
                                                            <th>Codigo Faena</th>
                                                            <th>Fecha Registro</th>
                                                            <th>Estado</th>
                                                            <th>Acciones</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {listadoFaenas.length > 0 ? (
                                                            listadoFaenas.map((val, key) => (
                                                                <tr key={key}>
                                                                    <td>{val.ID_Faena}</td>
                                                                    <td>{val.Nombre_Faena}</td>
                                                                    <td>{val.Codigo_Faena}</td>
                                                                    <td>{moment(val.Fecha_Registro).format('DD/MM/YYYY')}</td>
                                                                    <td>{val.Estado}</td>
                                                                    <td>
                                                                        <div className="btn-group" role="group" aria-label="Basic example">
                                                                            <button type="button" onClick={() => {FaenaActiva(val.ID_Faena, val.Nombre_Faena, val.Codigo_Faena)  }} className="btn btn-success">Seleccionar</button>
                                                                            <button type="button" onClick={() => {  }} className="btn btn-warning">Editar</button>
                                                                            <button type="button" onClick={() => {  }} className="btn btn-danger">Eliminar</button>
                                                                        </div>
                                                                    </td>
                                                                    {/* <td><button type="button" onClick={() => { deleteFile(archivo.ID_Registro); }} className="btn btn-danger">Eliminar</button></td> */}
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="4">No hay registros en la base de datos.</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    )
}

export default Faenas

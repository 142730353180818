import React, { useState, useEffect } from "react"
import { useCookies } from 'react-cookie';
import Sidebar from './SideBar';
import UserPage from "./UserPage";
import useAuthRedirect from "./Componentes/auth";
import Axios from 'axios';
import config from './Componentes/config';
import Swal from 'sweetalert2';
import { registrarLog } from './Componentes/logService';
import { validarArchivo, validarLiquidacion } from './Validations/TrabajadoresValidation';
import moment from 'moment';
import "moment/locale/es";


const cardImages = {
    DAFA: "/img/dafaLogo.png",
    FUGA: "/img/fugarLogo.png"
};

function Liquidaciones() {
    useAuthRedirect();
    const [cookies] = useCookies(['selectedCard']);
    const imageUrl = cardImages[cookies.selectedCard]
    const [isComponentsCollapsed, setIsComponentsCollapsed] = useState(true);
    const [isUtilitiesCollapsed, setIsUtilitiesCollapsed] = useState(true);
    const [isInventario, setisIventatioCollapsed] = useState(true);
    const [isPrevencion, setisPrevencionCollapsed] = useState(true);
    const [isLogistica, setisLogisticaCollapsed] = useState(true);
    const toggleCollapse = (collapseSetter) => {
        collapseSetter(prevState => !prevState);
    };
    const getEmpresaName = (card) => {
        switch (card) {
            case 'FUGA':
                return 'FUGAR SERVICIOS INDUSTRIALES SPA';
            case 'DAFA':
                return 'DAFA FACILITY SERVICES SPA';
            default:
                return ''; // Retorna un string vacío o un valor por defecto si no coincide
        }
    };
    // Estados para el componente Trabajadores
    moment.locale("es");
    const [empresa, setEmpresa] = useState(getEmpresaName(cookies.selectedCard));
    const [trabajadores, setTrabajadores] = useState([]);
    const [idTrabajadorSeleccionado, setIdTrabajadorSeleccionado] = useState('');
    const [nombreTrabajadorSeleccionado, setNombreTrabajadorSeleccionado] = useState('');
    const [rutTrabajadorSeleccionado, setRutTrabajadorSeleccionado] = useState('');
    const idEmpresa = cookies.idEmpresa;
    const [seleccion, setSeleccion] = useState(false);
    const [editar, setEditar] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [files, setFiles] = useState([]);
    const [archivosDB, setArchivosDB] = useState([]);
    const [fechaCarga, setfechaCarga] = useState('');
    const seleccionTrue = (ID) => {
        setSeleccion(true);
        console.log('ID:' + ID);
        fetchFiles();
    }


    const onFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };
    const onFileUpload = async () => {
        const mensajeError = validarLiquidacion(selectedFile, fechaCarga);

        if (mensajeError) {
            Swal.fire('Advertencia', mensajeError, 'warning');
            return; // Detiene la función si falla la validación
        }
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("Rut", rutTrabajadorSeleccionado); // Suponiendo que 'rut' está disponible en el estado
        formData.append("TipoArchivo", "Liquidaciones"); // Suponiendo que 'rut' está disponible en el estado
        formData.append("FechaCarga", moment(fechaCarga).format('YYYY-MM-DD HH:mm:ss')); // Agrega 'apellidos' si está disponible
        formData.append("UsuarioCarga", cookies.usuario); // Suponiendo que 'mail' contiene la fecha de carga
        try {
            const response = await Axios.post(`${config.API_URL}/SubeArchivo/${rutTrabajadorSeleccionado}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            Swal.fire(
                '¡Éxito!',
                'El archivo ha sido subido exitosamente.',
                'success'
            );
            fetchFiles();
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Liquidaciones',
                Detalle: `Agrega archivo de liquidacion: ${idTrabajadorSeleccionado}`,
                Estado: 'Exito',
            });
        } catch (error) {
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Trabajadores',
                Detalle: `Delete archivo de Liquidacion: ${idTrabajadorSeleccionado}`,
                Estado: 'Error',
            });
            Swal.fire(
                '¡Algo pasó!',
                'El archivo no ha podido subirse.',
                'error'
            );
        }
    }

    const fetchFiles = async () => {
        console.log("Llego pre fetch rut: " + fechaCarga);
        if (rutTrabajadorSeleccionado) {

            console.log("Llego post fetch");
            const datos = {
                Rut: rutTrabajadorSeleccionado,
                TipoArchivo: "Liquidaciones", // O cualquier otro tipo de archivo que desees
            };
            try {
                // Realizar la solicitud GET enviando los parámetros como query parameters
                setFiles([]);
                setArchivosDB([]);
                const response = await Axios.get(`${config.API_URL}/CargaArchivos`, {
                    params: datos, // Esto convierte los datos en query parameters
                });

                // Manejar la respuesta
                setFiles(Array.isArray(response.data.archivosDirectorio) ? response.data.archivosDirectorio : []);
                setArchivosDB(Array.isArray(response.data.archivosDB) ? response.data.archivosDB : []);
            } catch (error) {
                // Manejar el error si la solicitud falla
                setFiles([]);
                setArchivosDB([]);
                console.error("Error fetching files", error);
            }
        }
    };

    const deleteFile = (id) => {
        Swal.fire({
            title: '¡Cuidado!',
            html: '¿Está seguro de eliminar el archivo?.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar!'
        }).then(async (res) => {
            if (res.isConfirmed) {
                try {
                    await Axios.delete(`${config.API_URL}/EliminaArchivo/${id}`);
                    Swal.fire(
                        'Eliminado!',
                        'El trabajador ha sido eliminado exitosamente',
                        'success'
                    );
                    fetchFiles();
                    await registrarLog({
                        Usuario: cookies.usuario,
                        Modulo: 'Trabajadores',
                        Detalle: `Delete archivo de trabajador: ${id}`,
                        Estado: 'Exito',
                    });
                } catch (error) {
                    await registrarLog({
                        Usuario: cookies.usuario,
                        Modulo: 'Trabajadores',
                        Detalle: `Delete archivo de trabajador: ${id} - error: ${error}`,
                        Estado: 'Error',
                    });
                    Swal.fire(
                        'Error!',
                        'Hubo un problema al eliminar el archivo. Por favor intente nuevamente.',
                        'error'
                    );
                    console.error('Error al eliminar el archivo:', error);  // Esto es útil para depuración
                }
            }
        });
    };

    const getTrabajadores = () => {
        console.log("idEmpresa:", idEmpresa);  // Verifica que idEmpresa tiene el valor esperado
        console.log("intentando cargar trabajadores compensado");
        Axios.get(`${config.API_URL}/CargaTrabajadores?id=${idEmpresa}`)
            .then(res => {
                console.log(res.data); // Verifica la estructura de la respuesta
                if (Array.isArray(res.data)) {
                    setTrabajadores(res.data); // Asegúrate de que sea un array
                } else {
                    console.error('La respuesta no es un array:', res.data);
                    setTrabajadores([]); // Establece un array vacío si no es un array
                }
            })
            .catch(err => {
                console.error("Error al obtener los datos:", err);
            });
    }

    useEffect(() => {
        fetchFiles(); // Cargar los archivos cuando cambie el 'rut'
    }, [rutTrabajadorSeleccionado]);  // Se ejecutará cada vez que 'rut' cambie

    useEffect(() => {
        getTrabajadores();
    }, []);
    return (
        <body id="page-top">
            <div id="wrapper">
                <Sidebar
                    imageUrl={imageUrl}
                    isComponentsCollapsed={isComponentsCollapsed}
                    toggleCollapse={toggleCollapse}
                    setIsComponentsCollapsed={setIsComponentsCollapsed}
                    isUtilitiesCollapsed={isUtilitiesCollapsed}
                    setIsUtilitiesCollapsed={setIsUtilitiesCollapsed}
                    isInventario={isInventario}
                    setisIventatioCollapsed={setisIventatioCollapsed}
                    isPrevencion={isPrevencion}
                    setisPrevencionCollapsed={setisPrevencionCollapsed}
                    isLogistica={isLogistica}
                    setisLogisticaCollapsed={setisLogisticaCollapsed}
                />
                <div id="content-wrapper" class="d-flex flex-column">
                    <div id="content">
                        <UserPage cookies={cookies} />
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            <h6 class="m-0 font-weight-bold text-primary">Módulo de Liquidaciones de {empresa}</h6>
                                        </div>
                                        <div class="card-body">
                                            <div class="input-group mb-3">
                                                <span class="input-group-text" id="basic-addon1">Trabajador:</span>
                                                <select class="form-select" value={idTrabajadorSeleccionado}
                                                    onChange={(e) => {
                                                        setIdTrabajadorSeleccionado(e.target.value);
                                                        seleccionTrue(e.target.value);
                                                        setNombreTrabajadorSeleccionado(e.target.options[e.target.selectedIndex].text);
                                                        setRutTrabajadorSeleccionado(e.target.options[e.target.selectedIndex].getAttribute('data-rut'));
                                                    }}>
                                                    <option value="">Seleccione un trabajador:</option>
                                                    {trabajadores.map(trabajador => (
                                                        <option
                                                            key={trabajador.ID_Trabajador}
                                                            value={trabajador.ID_Trabajador}
                                                            data-rut={trabajador.Rut}>
                                                            {trabajador.NombreCompleto}
                                                        </option>
                                                    ))}
                                                </select>
                                                <span class="input-group-text" id="basic-addon1">Fecha liquidación:</span>
                                                <input type="month" class="form-control" value={fechaCarga} onChange={(event) => setfechaCarga(event.target.value)}></input>
                                            </div>
                                            <hr />
                                            <div class="card-footer text-muted">
                                                {
                                                    seleccion ?
                                                        <div>
                                                            <div className="input-group mb-3">
                                                                <input type="file" className="form-control" onChange={onFileChange} />
                                                            </div>
                                                            {editar ? (
                                                                <div>
                                                                    <button class="btn btn-primary" >Actualizar</button>
                                                                </div>
                                                            ) : (
                                                                <button class="btn btn-primary" onClick={onFileUpload}>Registrar</button>

                                                            )}
                                                        </div>
                                                        : <label></label>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-lg-12">
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            <h6 class="m-0 font-weight-bold text-primary">Listado de Liquidaciones del trabajador</h6>
                                        </div>
                                        <div class="card-body">
                                            {
                                                seleccion ?
                                                    <div>
                                                        <div class="table-responsive">
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th>ID</th>
                                                                        <th>Fecha Carga</th>
                                                                        <th>Usuario Carga</th>
                                                                        <th>Descargar archivo</th>
                                                                        <th>Acción</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {archivosDB.length > 0 ? (
                                                                        archivosDB.map((archivo, index) => (
                                                                            <tr key={index}>
                                                                                <td>{archivo.ID_Registro}</td>
                                                                                <td>{moment(archivo.Fecha_Carga, "YYYY/MM/DD").format("MMMM YYYY")}</td>
                                                                                <td>{archivo.Usuario_Carga}</td>
                                                                                <td>
                                                                                    {/* Usa el nombre de archivo de la base de datos para el enlace de descarga */}
                                                                                    <a
                                                                                        href={`${config.API_URL}/uploads/${rutTrabajadorSeleccionado}/${encodeURIComponent(archivo.Nombre_Archivo)}`}
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                    >
                                                                                         <i className="fas fa-download"></i>
                                                                                    </a>
                                                                                </td>
                                                                                <td><button type="button" onClick={() => { deleteFile(archivo.ID_Registro); }} className="btn btn-danger">Eliminar</button></td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan="4">No hay archivos en la base de datos.</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    : <label></label>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    )
}

export default Liquidaciones

import React, { useState, useEffect } from "react"
import { useCookies } from 'react-cookie';
import Sidebar from './SideBar';
import UserPage from "./UserPage";
import useAuthRedirect from "./Componentes/auth";
import Axios from 'axios';
import config from './Componentes/config';
import { validarVacaciones, cleanRut } from './Validations/TrabajadoresValidation';
import { registrarLog } from './Componentes/logService';
import Swal from 'sweetalert2';
import moment from 'moment';
import generarPDF from './Documentos/vacaciones';
import { formatRutEmpresa, formatRutDV, validarArchivo } from './Validations/TrabajadoresValidation';
const cardImages = {
    DAFA: "/img/dafaLogo.png",
    FUGA: "/img/fugarLogo.png"
};

function Vacaciones() {
    useAuthRedirect();
    const [cookies] = useCookies(['selectedCard']);
    const imageUrl = cardImages[cookies.selectedCard]
    const [isComponentsCollapsed, setIsComponentsCollapsed] = useState(true);
    const [isUtilitiesCollapsed, setIsUtilitiesCollapsed] = useState(true);
    const [isInventario, setisIventatioCollapsed] = useState(true);
    const [isPrevencion, setisPrevencionCollapsed] = useState(true);
    const [isLogistica, setisLogisticaCollapsed] = useState(true);
    const toggleCollapse = (collapseSetter) => {
        collapseSetter(prevState => !prevState);
    };
    const idEmpresa = cookies.idEmpresa;
    const [diasDisponibles, setDiasDisponibles] = useState(0);
    // const [solicitudes, setSolicitudes] = useState([]);
    // const [diasSolicitados, setDiasSolicitados] = useState('');
    const [trabajadores, setTrabajadores] = useState([]);
    const [idTrabajadorSeleccionado, setIdTrabajadorSeleccionado] = useState('');
    const [seleccion, setSeleccion] = useState(false);
    const [nombreTrabajadorSeleccionado, setNombreTrabajadorSeleccionado] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [inicio, setInicio] = useState('');
    const [termino, setTermino] = useState('');
    const [Observacion, setObservacion] = useState('');
    const [diasHabiles, setDiasHabiles] = useState(0);
    const [diasTomados, setDiasTomados] = useState(0);
    const [listadoVacaciones, setlistadoVacaciones] = useState([]);
    const [files, setFiles] = useState([]);
    const [archivosDB, setArchivosDB] = useState([]);
    const [rutTrabajadorSeleccionado, setRutTrabajadorSeleccionado] = useState('');
    const [editar, setEditar] = useState(false);
    const [TipoArchivo, setTipoArchivo] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);

    const calcularDiasHabiles = (inicio, termino) => {
        if (inicio && termino) {
            // Convertimos las fechas de entrada a formato yyyy-mm-dd para evitar problemas de horas
            const fechaInicio = new Date(inicio + 'T00:00:00');  // Añadimos una hora ficticia para evitar problemas de hora
            const fechaTermino = new Date(termino + 'T00:00:00');  // Lo mismo con la fecha de término
    
            let diasHabiles = 0;
    
            // Lista de días festivos en Chile (ajustar según el año y las leyes locales)
            const diasFestivos = [
                // 2024
                '2024-01-01', // Año Nuevo
                '2024-03-29', // Viernes santo
                '2024-05-01', // Día del Trabajo
                '2024-05-21', // Día del Trabajo
                '2024-06-07', // San Pedro y San Pablo
                '2024-06-20', // San Pedro y San Pablo
                '2024-08-15', // Asunción de la Virgen
                '2024-08-20', // Asunción de la Virgen
                '2024-09-18', // Fiestas Patrias
                '2024-09-19', // Día de las Glorias del Ejército
                '2024-09-20', // Día de las Glorias del Ejército
                '2024-10-31', // Halloween
                '2024-11-01', // Día de Todos los Santos
                '2024-12-25', // Navidad
                
                // 2025
                '2025-01-01', // Año Nuevo
                '2025-04-18', // Viernes Santo
                '2025-05-01', // Día del Trabajo
                '2025-05-21', // Día del Trabajo
                '2025-06-20', // San Pedro y San Pablo
                '2025-07-16', // Asunción de la Virgen
                '2025-08-15', // Asunción de la Virgen
                '2025-08-20', // Asunción de la Virgen
                '2025-09-18', // Fiestas Patrias
                '2025-09-19', // Día de las Glorias del Ejército
                '2025-10-31', // Día del Encuentro de Dos Mundos
                '2025-12-08', // Inmaculada Concepción
                '2025-12-25', // Navidad
            ];
    
            // Función para verificar si una fecha es festivo
            const esFestivo = (fecha) => {
                return diasFestivos.includes(fecha.toISOString().split('T')[0]); // Convertir la fecha a formato yyyy-mm-dd
            };
    
            // Bucle que recorre desde la fecha de inicio hasta la fecha de término (inclusive)
            while (fechaInicio <= fechaTermino) {
                const diaSemana = fechaInicio.getDay();
                if (diaSemana !== 0 && diaSemana !== 6 && !esFestivo(fechaInicio)) { // Excluir sábados, domingos y festivos
                    diasHabiles++;
                }
                fechaInicio.setDate(fechaInicio.getDate() + 1); // Incrementar al siguiente día
            }
    
            setDiasHabiles(diasHabiles); // Actualizamos el estado correctamente
        }
    };
    

    const onFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };
    const onFileUpload = async () => {
        const mensajeError = validarArchivo(selectedFile, "Vacaciones");

        if (mensajeError) {
            Swal.fire('Advertencia', mensajeError, 'warning');
            return; // Detiene la función si falla la validación
        }
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("Rut", rutTrabajadorSeleccionado); // Suponiendo que 'rut' está disponible en el estado
        formData.append("TipoArchivo", "Vacaciones"); // Suponiendo que 'rut' está disponible en el estado
        formData.append("FechaCarga", moment().format('YYYY-MM-DD HH:mm:ss')); // Agrega 'apellidos' si está disponible
        formData.append("UsuarioCarga", cookies.usuario); // Suponiendo que 'mail' contiene la fecha de carga
        try {
            const response = await Axios.post(`${config.API_URL}/SubeArchivo/${rutTrabajadorSeleccionado}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            Swal.fire(
                '¡Éxito!',
                'El archivo ha sido subido exitosamente.',
                'success'
            );
            fetchFiles();
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Trabajadores',
                Detalle: `Agrega archivo de Vacaciones: ${idTrabajadorSeleccionado}`,
                Estado: 'Exito',
            });
        } catch (error) {
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Trabajadores',
                Detalle: `Delete archivo de Vacaciones: ${idTrabajadorSeleccionado}`,
                Estado: 'Error',
            });
            Swal.fire(
                '¡Algo pasó!',
                'El archivo no ha podido subirse.',
                'error'
            );
        }
    }
    const getEmpresaName = (card) => {
        switch (card) {
            case 'FUGA':
                return 'FUGAR SERVICIOS INDUSTRIALES SPA';
            case 'DAFA':
                return 'DAFA FACILITY SERVICES SPA';
            default:
                return ''; // Retorna un string vacío o un valor por defecto si no coincide
        }
    };
    const [empresa, setEmpresa] = useState(getEmpresaName(cookies.selectedCard));
    const seleccionTrue = (ID) => {
        setSeleccion(true);
        console.log('ID:' + ID);
        calculaVacaciones(ID);
        cargaVacaciones(ID);
    }

    const getTrabajadores = () => {
        console.log("idEmpresa:", idEmpresa);  // Verifica que idEmpresa tiene el valor esperado
        console.log("intentando cargar trabajadores compensado");
        Axios.get(`${config.API_URL}/CargaTrabajadores?id=${idEmpresa}`)
            .then(res => {
                console.log(res.data); // Verifica la estructura de la respuesta
                if (Array.isArray(res.data)) {
                    setTrabajadores(res.data); // Asegúrate de que sea un array
                } else {
                    console.error('La respuesta no es un array:', res.data);
                    setTrabajadores([]); // Establece un array vacío si no es un array
                }
            })
            .catch(err => {
                console.error("Error al obtener los datos:", err);
            });
    }

    const calculaVacaciones = (ID) => {
        console.log('Llamando funcionj de calcular vacacion ID=' + ID)
        Axios.get(`${config.API_URL}/calculaVacaciones?id=${ID}`)
            .then(res => {
                setDiasDisponibles(res.data.vacacionesDisponibles); // Asume que el backend devuelve un número directamente
                setDiasTomados(res.data.diasSolicitados);
                console.log("Vacaciones disponibles:", setDiasDisponibles);
                // Puedes utilizar el valor aquí según sea necesario
            })
            .catch(err => {
                console.error("Error al obtener los datos:", err);
            });
    };

    const RegistraVacaciones = async () => {
        const mensajeError = validarVacaciones(inicio, termino, diasDisponibles, diasHabiles);
        if (mensajeError) {
            Swal.fire('Advertencia', mensajeError, 'warning');
            return; // Detiene la función si falla la validación
        }
        const datos = {
            idTrabajador: idTrabajadorSeleccionado,
            diasSolicitados: diasHabiles,
            estado: 'Pendiente',
            solicitante: cookies.usuario,
            Observacion: Observacion,
            Inicio: inicio,
            Termino: termino
        };
        try {
            const response = await Axios.post(`${config.API_URL}/registraVacaciones`, datos, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Vacaciones',
                Detalle: `Registro de vacaciones trabajador ID: ${idTrabajadorSeleccionado}`,
                Estado: 'Éxito',
            });
            Swal.fire(
                '¡Éxito!',
                'La solicitud de vacaciones se envió exitosamente',
                'success'
            );
            setInicio("");
            setTermino("");
            setObservacion("");
            cargaVacaciones(idTrabajadorSeleccionado);
        } catch (error) {
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Vacaciones',
                Detalle: `Registro de vacaciones trabajador ID: ${idTrabajadorSeleccionado} - Error: ${error.message || error}`,
                Estado: 'Fallo',
            });
            Swal.fire(
                '¡Algo pasó!',
                'Hubo un error al enviar la petición.',
                'error'
            );
        }
    }

    const cargaVacaciones = (ID) => {
        Axios.get(`${config.API_URL}/cargaVacaciones?id=${ID}`)
            .then(res => {
                console.log(res.data); // Verifica la estructura de la respuesta
                if (Array.isArray(res.data)) {
                    setlistadoVacaciones(res.data); // Asegúrate de que sea un array
                } else {
                    console.error('La respuesta no es un array:', res.data);
                    setlistadoVacaciones([]); // Establece un array vacío si no es un array
                }
            })
            .catch(err => {
                console.error("Error al obtener los datos:", err);
            });
    }

    const cargaVacacionesPDF = (ID) => {
        Axios.get(`${config.API_URL}/cargaDatoVacaciones?id=${ID}`)
            .then(res => {
                console.log(res.data + " cliente"); // Verifica la estructura de la respuesta
                // Supongamos que la respuesta contiene un objeto con los datos requeridos
                if (res.data && res.data.Empresa) {
                    const vacationData = res.data;

                    // Asigna los datos a las variables
                    const Empresa = vacationData.Empresa;
                    const RutEmpresa = formatRutEmpresa(vacationData.RutEmpresa);
                    const NombreTrabajador = vacationData.NombreTrabajador;
                    const RutTrabajador = formatRutDV(vacationData.RutTrabajador);
                    const diasVacaciones = vacationData.diasVacaciones;
                    const fechainicio = moment(vacationData.fechainicio).format('DD/MM/YYYY');
                    const fechafin = moment(vacationData.fechafin).format('DD/MM/YYYY');

                    // Llama a la función generarPDF con los datos
                    generarPDF(Empresa, RutEmpresa, NombreTrabajador, RutTrabajador, diasVacaciones, fechainicio, fechafin);
                } else {
                    console.error('La respuesta no contiene los datos esperados');
                }
            })
            .catch(err => {
                console.error("Error al obtener los datos:", err);
            });
    };

    const fetchFiles = async () => {
        if (rutTrabajadorSeleccionado) {
            const datos = {
                Rut: rutTrabajadorSeleccionado,
                TipoArchivo: "Vacaciones", // O cualquier otro tipo de archivo que desees
            };
            try {
                // Realizar la solicitud GET enviando los parámetros como query parameters
                setFiles([]);
                setArchivosDB([]);    
                const response = await Axios.get(`${config.API_URL}/CargaArchivos`, {
                    params: datos, // Esto convierte los datos en query parameters
                });

                // Manejar la respuesta
                setFiles(Array.isArray(response.data.archivosDirectorio) ? response.data.archivosDirectorio : []);
                setArchivosDB(Array.isArray(response.data.archivosDB) ? response.data.archivosDB : []);
            } catch (error) {
                // Manejar el error si la solicitud falla
                setFiles([]);
                setArchivosDB([]);    
                console.error("Error fetching files", error);
            }
        }
    };

    const deleteFile = (id) => {
        Swal.fire({
            title: '¡Cuidado!',
            html: '¿Está seguro de eliminar el archivo?.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar!'
        }).then(async (res) => {
            if (res.isConfirmed) {
                try {
                    await Axios.delete(`${config.API_URL}/EliminaArchivo/${id}`);
                    Swal.fire(
                        'Eliminado!',
                        'El trabajador ha sido eliminado exitosamente',
                        'success'
                    );
                    fetchFiles();
                    await registrarLog({
                        Usuario: cookies.usuario,
                        Modulo: 'Trabajadores',
                        Detalle: `Delete archivo de trabajador: ${id}`,
                        Estado: 'Exito',
                    });
                } catch (error) {
                    await registrarLog({
                        Usuario: cookies.usuario,
                        Modulo: 'Trabajadores',
                        Detalle: `Delete archivo de trabajador: ${id} - error: ${error}`,
                        Estado: 'Error',
                    });
                    Swal.fire(
                        'Error!',
                        'Hubo un problema al eliminar el archivo. Por favor intente nuevamente.',
                        'error'
                    );
                    console.error('Error al eliminar el archivo:', error);  // Esto es útil para depuración
                }
            }
        });
    };

    useEffect(() => {
        fetchFiles(); // Cargar los archivos cuando cambie el 'rut'
    }, [rutTrabajadorSeleccionado]);  // Se ejecutará cada vez que 'rut' cambie

    useEffect(() => {
        getTrabajadores();
    }, []);

    // useEffect(() => {
    //     getListadoVacaciones();
    // }, []);

    return (
        <body id="page-top">
            <div id="wrapper">
                <Sidebar
                    imageUrl={imageUrl}
                    isComponentsCollapsed={isComponentsCollapsed}
                    toggleCollapse={toggleCollapse}
                    setIsComponentsCollapsed={setIsComponentsCollapsed}
                    isUtilitiesCollapsed={isUtilitiesCollapsed}
                    setIsUtilitiesCollapsed={setIsUtilitiesCollapsed}
                    isInventario={isInventario}
                    setisIventatioCollapsed={setisIventatioCollapsed}
                    isPrevencion={isPrevencion}
                    setisPrevencionCollapsed={setisPrevencionCollapsed}
                    isLogistica={isLogistica}
                    setisLogisticaCollapsed={setisLogisticaCollapsed}
                />
                <div id="content-wrapper" class="d-flex flex-column">
                    <div id="content">
                        <UserPage cookies={cookies} />
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            <h6 class="m-0 font-weight-bold text-primary">Modulo vacaciones de {empresa}</h6>
                                        </div>
                                        <div class="card-body">
                                            <div class="input-group mb-3">
                                                <div class="col-md-4">
                                                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                        Trabajador:
                                                    </div>
                                                    <select class="form-select" value={idTrabajadorSeleccionado}
                                                        onChange={(e) => {
                                                            setIdTrabajadorSeleccionado(e.target.value);
                                                            seleccionTrue(e.target.value);
                                                            setNombreTrabajadorSeleccionado(e.target.options[e.target.selectedIndex].text);
                                                            setRutTrabajadorSeleccionado(e.target.options[e.target.selectedIndex].getAttribute('data-rut'));
                                                        }}>
                                                        <option value="">Seleccione un trabajador:</option>
                                                        {trabajadores.map(trabajador => (
                                                            <option
                                                                key={trabajador.ID_Trabajador}
                                                                value={trabajador.ID_Trabajador}
                                                                data-rut={trabajador.Rut}>
                                                                {trabajador.NombreCompleto}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="card border-left-success shadow h-100 py-1">
                                                        <div class="card-body p-1">
                                                            <div class="row no-gutters align-items-center">
                                                                <div class="col">
                                                                    <div class="text-xs font-weight-bold text-success text-uppercase text-truncate">
                                                                        Días disponibles
                                                                    </div>
                                                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{diasDisponibles}</div>
                                                                </div>
                                                                <div class="col-auto">
                                                                    <i class="fas fa-calendar fa-2x text-gray-300"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="card border-left-danger shadow h-100 py-1">
                                                        <div class="card-body p-1">
                                                            <div class="row no-gutters align-items-center">
                                                                <div class="col">
                                                                    <div class="text-xs font-weight-bold text-danger text-uppercase text-truncate">
                                                                        Días tomados
                                                                    </div>
                                                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{diasTomados}</div>
                                                                </div>
                                                                <div class="col-auto">
                                                                    <i class="fas fa-calendar fa-2x text-gray-300"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <button
                                                        className="btn btn-success m-2"
                                                        onClick={() => setModalVisible(true)}
                                                    >
                                                        Solicitar
                                                    </button>
                                                    <br />
                                                </div>
                                                <div class="col-md-12">
                                                    <h6 class="m-0 font-weight-bold text-primary">Listado de vacaciones</h6>
                                                    <br />
                                                    <table class="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                                        <thead>
                                                            <tr>
                                                                <th>N°</th>
                                                                <th>Fecha solicitud</th>
                                                                <th>Dias solicitados</th>
                                                                <th>Periodo</th>
                                                                <th>Solicitante</th>
                                                                <th>Aprobado por</th>
                                                                <th>Observación</th>
                                                                <th>Estado</th>
                                                                <th>Comprobante</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                Array.isArray(listadoVacaciones) && listadoVacaciones.length > 0 ? (
                                                                    listadoVacaciones.map((val, key) => (
                                                                        <tr key={key}>
                                                                            <td>{val.ID_Solicitud}</td>
                                                                            <td>{moment(val.Fecha_Solicitud).format('DD/MM/YYYY')}</td>
                                                                            <td>{val.Dias_Solicitados}</td>
                                                                            <td>{moment(val.Fecha_Inicio).format('DD/MM/YYYY')} al {moment(val.Fecha_Fin).format('DD/MM/YYYY')}</td>
                                                                            <td>{val.Solicitante}</td>
                                                                            <td>{val.Aprueba}</td>
                                                                            <td>{val.Observacion}</td>
                                                                            <td>{val.Estado}</td>
                                                                            <td>
                                                                                {val.Estado === "Aprobado" && (
                                                                                    <button
                                                                                        className="btn btn-secondary"
                                                                                        onClick={() => cargaVacacionesPDF(val.ID_Solicitud)}
                                                                                    >
                                                                                        <i className="fas fa-file-pdf"></i> {/* Ícono */}
                                                                                    </button>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr><td colSpan="15">No hay información de solicitudes pendientes.</td></tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="card shadow mb-4">
                                                        <div class="card-header py-3">
                                                            <h6 class="m-0 font-weight-bold text-primary">Listado de archivos</h6>
                                                        </div>
                                                        <div class="card-body">
                                                            <label>Archivos asociados al trabajador:</label>
                                                            <div>
                                                                <div class="table-responsive">
                                                                    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>ID</th>
                                                                                <th>Fecha Carga</th>
                                                                                <th>Usuario Carga</th>
                                                                                <th>Archivo</th>
                                                                                <th>Acción</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {archivosDB.length > 0 ? (
                                                                                archivosDB.map((archivo, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>{archivo.ID_Registro}</td>
                                                                                        <td>{moment(archivo.Fecha_Carga).format('DD/MM/YYYY')}</td>
                                                                                        <td>{archivo.Usuario_Carga}</td>
                                                                                        <td>
                                                                                            {/* Usa el nombre de archivo de la base de datos para el enlace de descarga */}
                                                                                            <a
                                                                                                href={`${config.API_URL}/uploads/${rutTrabajadorSeleccionado}/${encodeURIComponent(archivo.Nombre_Archivo)}`}
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                            >
                                                                                                {archivo.Nombre_Archivo} {/* Muestra el nombre del archivo */}
                                                                                            </a>
                                                                                        </td>
                                                                                        <td><button type="button" onClick={() => { deleteFile(archivo.ID_Registro); }} className="btn btn-danger">Eliminar</button></td>
                                                                                    </tr>
                                                                                ))
                                                                            ) : (
                                                                                <tr>
                                                                                    <td colSpan="4">No hay archivos en la base de datos.</td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <br></br>

                                                            <div class="card-footer text-muted">
                                                                {setSeleccion ? (
                                                                    <div>
                                                                        <label class="form-label">Ingresar archivo trabajador</label>
                                                                        <div className="input-group mb-3">
                                                                            <span class="input-group-text" id="basic-addon1">Tipo archivo:</span>
                                                                            {TipoArchivo.startsWith("Otros:") && (
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Especifique tipo de archivo"
                                                                                    value={TipoArchivo.replace("Otros:", "")} // Muestra solo el contenido manual
                                                                                    onChange={(event) => setTipoArchivo(`Otros:${event.target.value}`)} // Actualiza el texto manualmente
                                                                                />
                                                                            )}
                                                                            <input type="file" className="form-control" onChange={onFileChange} />
                                                                        </div>
                                                                        <button className="btn btn-success" onClick={onFileUpload}>Subir</button>
                                                                    </div>
                                                                ) : (
                                                                    <label className="form-label">Seleccione un trabajador para subir un archivo.</label>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            {modalVisible && (
                <div
                    className="modal fade show d-block"
                    tabIndex="-1"
                    role="dialog"
                    style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Solicitar Vacación</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => setModalVisible(false)}
                                ></button>
                            </div>
                            <div className="modal-body">
                                {/* <div className="mb-3">
                                    <label className="form-label">Tipo Vacación</label>
                                    <select className="form-select">
                                        <option value="">Seleccione</option>
                                        <option value="Legales">Legales</option>
                                        <option value="Progresivas">Progresivas</option>
                                    </select>
                                </div> */}
                                <div className="mb-3 d-flex justify-content-between">
                                    <div className="w-50 me-2">
                                        <label className="form-label">Inicio</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={inicio}
                                            onChange={(e) => {
                                                setInicio(e.target.value);
                                                calcularDiasHabiles(e.target.value, termino);
                                            }}
                                        />
                                    </div>
                                    <div className="w-50">
                                        <label className="form-label">Término (inclusive)</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={termino}
                                            onChange={(e) => {
                                                setTermino(e.target.value);
                                                calcularDiasHabiles(inicio, e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Días hábiles</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={diasHabiles}
                                        readOnly
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Observación</label>
                                    <input type="text" className="form-control" value={Observacion} onChange={(event) => { setObservacion(event.target.value); }} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => setModalVisible(false)}
                                >
                                    Cerrar
                                </button>
                                <button className="btn btn-success" onClick={RegistraVacaciones}>Solicitar</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </body>
    );
}

export default Vacaciones

import React, { useState, useEffect } from "react"
import { useCookies } from 'react-cookie';
import Sidebar from '../SideBar';
import UserPage from "../UserPage";
import useAuthRedirect from "../Componentes/auth";
import Axios from 'axios';
import config from '../Componentes/config';
import Swal from 'sweetalert2';
import $ from "jquery";
import { validarArchivo } from '../Validations/TrabajadoresValidation';
import moment from 'moment';
import { registrarLog } from '../Componentes/logService';


const cardImages = {
    DAFA: "/img/dafaLogo.png",
    FUGA: "/img/fugarLogo.png"
};



function Historialtrabajador() {
    useAuthRedirect();
    const [cookies] = useCookies(['selectedCard']);
    const imageUrl = cardImages[cookies.selectedCard]
    const [isComponentsCollapsed, setIsComponentsCollapsed] = useState(true);
    const [isUtilitiesCollapsed, setIsUtilitiesCollapsed] = useState(true);
    const [isInventario, setisIventatioCollapsed] = useState(true);
    const [isPrevencion, setisPrevencionCollapsed] = useState(true);
    const [isLogistica, setisLogisticaCollapsed] = useState(true);
    const toggleCollapse = (collapseSetter) => {
        collapseSetter(prevState => !prevState);
    };
    const getEmpresaName = (card) => {
        switch (card) {
            case 'FUGA':
                return 'FUGAR SERVICIOS INDUSTRIALES SPA';
            case 'DAFA':
                return 'DAFA FACILITY SERVICES SPA';
            default:
                return ''; // Retorna un string vacío o un valor por defecto si no coincide
        }
    };
    // Estados para el componente Trabajadores
    const [empresa, setEmpresa] = useState(getEmpresaName(cookies.selectedCard));
    const [trabajadores, setTrabajadores] = useState([]);
    const [rutSelected, setrutSelected] = useState('');
    const [nombreTrabajadorSeleccionado, setNombreTrabajadorSeleccionado] = useState('');
    const [seleccion, setSeleccion] = useState(false);
    const idEmpresa = cookies.idEmpresa;
    const NombreEmpresa = cookies.NombreEmpresa;
    const [tipoArchivo, settipoArchivo] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [files, setFiles] = useState([]);
    const [archivosDB, setArchivosDB] = useState([]);
    const getTrabajadores = () => {
        console.log("idEmpresa:", idEmpresa);  // Verifica que idEmpresa tiene el valor esperado
        console.log("intentando cargar trabajadores compensado");
        Axios.get(`${config.API_URL}/CargaTrabajadores?id=${idEmpresa}`)
            .then(res => {
                console.log(res.data); // Verifica la estructura de la respuesta
                if (Array.isArray(res.data)) {
                    setTrabajadores(res.data); // Asegúrate de que sea un array
                } else {
                    console.error('La respuesta no es un array:', res.data);
                    setTrabajadores([]); // Establece un array vacío si no es un array
                }
            })
            .catch(err => {
                console.error("Error al obtener los datos:", err);
            });
    }
    const onFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    // const seleccionTrue = () => {
    //     console.log("llego aqui");
    //     setSeleccion(true);
    //     fetchFiles();
    // }

    const onFileUpload = async () => {
        console.log(`RUT:${rutSelected} Prevencion:${tipoArchivo}`)
        const mensajeError = validarArchivo(selectedFile, tipoArchivo);

        if (mensajeError) {
            Swal.fire('Advertencia', mensajeError, 'warning');
            return; // Detiene la función si falla la validación
        }
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("Rut", rutSelected); // Suponiendo que 'rut' está disponible en el estado
        formData.append("TipoArchivo", `Prevencion:${tipoArchivo}`); // Suponiendo que 'rut' está disponible en el estado
        formData.append("FechaCarga", moment().format('YYYY-MM-DD HH:mm:ss')); // Agrega 'apellidos' si está disponible
        formData.append("UsuarioCarga", cookies.usuario); // Suponiendo que 'mail' contiene la fecha de carga
        try {
            const response = await Axios.post(`${config.API_URL}/SubeArchivo/${rutSelected}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            Swal.fire(
                '¡Éxito!',
                'El archivo ha sido subido exitosamente.',
                'success'
            );
            //   fetchFiles();
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Historial prevencion',
                Detalle: `Agrega archivo de trabajador RUT: ${rutSelected}`,
                Estado: 'Exito',
            });
        } catch (error) {
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Prevencion',
                Detalle: `Error al agregar archivo de trabajador RUT: ${rutSelected}`,
                Estado: 'Error',
            });
            Swal.fire(
                '¡Algo pasó!',
                'El archivo no ha podido subirse.',
                'error'
            );
        }
    }

    const fetchFiles = async () => {
        console.log(rutSelected);
        if (rutSelected) {
            console.log("Llego a fetchfiles");
            const datos = {
                Rut: rutSelected,
                TipoArchivo: "Prevencion", // O cualquier otro tipo de archivo que desees
            };
            try {
                // Limpiar los datos anteriores
                setFiles([]);
                setArchivosDB([]);
    
                // Realizamos la solicitud
                const response = await Axios.get(`${config.API_URL}/CargaArchivos`, {
                    params: datos, // Enviar los datos como query parameters
                });
    
                // Manejar la respuesta
                const archivos = Array.isArray(response.data.archivosDB) ? response.data.archivosDB : [];
                setArchivosDB(archivos); // Establecer archivosDB
    
                // Si no hay registros en archivosDB, mostrar "sin actividad"
                if (archivos.length === 0) {
                    setArchivosDB([{ Nombre_Archivo: 'Sin actividad' }]); // Coloca un solo registro con 'Sin actividad'
                } else {
                    setFiles(Array.isArray(response.data.archivosDirectorio) ? response.data.archivosDirectorio : []);
                }
            } catch (error) {
                // Manejar el error si la solicitud falla
                setFiles([]);
                setArchivosDB([]);
                console.error("Error fetching files", error);
            }
        }
    };
    


    useEffect(() => {
        getTrabajadores();
    }, []);

    const handleSelectChange = (e) => {
        setrutSelected(e.target.value);
    };

    useEffect(() => {
        if (rutSelected) {
            console.log("Rut actualizado:", rutSelected);
            // Llamar la función que depende de rutSelected aquí
            fetchFiles();
        }
    }, [rutSelected]); // Se ejecuta cada vez que rutSelected cambia


    useEffect(() => {
        console.log("archivosDB:", archivosDB);
    
        // Verificar si no hay archivos y mostrar "sin actividad"
        if (archivosDB.length > 0) {
            $("#miTabla").DataTable({
                destroy: true, // Para evitar inicializaciones duplicadas
                paging: true,
                searching: true,
                ordering: true,
                info: true,
                autoWidth: false,
                responsive: true,
                data: archivosDB, // Pasamos los datos de archivosDB
                columns: [
                    { title: "ID", data: "ID_Registro" },
                    { 
                        title: "Nombre archivo", 
                        data: "Nombre_Archivo",
                        render: function (data) {
                            return decodeURIComponent(escape(data)); 
                        }
                    },
                    { 
                        title: "Tipo Documento", 
                        data: "Tipo_Archivo",
                        render: function (data) {
                            return data.includes(":") ? data.split(":")[1] : data; // Si tiene ":", toma lo que está después
                        }
                    },
                    {
                        title: "Fecha Carga",
                        data: "Fecha_Carga",
                        render: function (data) {
                            return data ? moment(data).format("DD/MM/YYYY") : "Sin fecha";
                        }
                    },
                    {
                        title: "Acción",
                        data: "Nombre_Archivo",
                        render: function (data) {
                            return data ? `<a href="${config.API_URL}/uploads/${rutSelected}/${encodeURIComponent(data)}" target="_blank" rel="noopener noreferrer">Descargar</a>` : "No disponible";
                        }
                    }
                ],
                language: {
                    search: "Buscar:",
                    lengthMenu: "Mostrar _MENU_ registros",
                    info: "Mostrando _START_ a _END_ de _TOTAL_ registros",
                    infoEmpty: "No hay registros disponibles",
                    infoFiltered: "(filtrado de _MAX_ registros totales)",
                    loadingRecords: "Cargando...",
                    zeroRecords: "No se encontraron registros",
                    emptyTable: "No hay datos disponibles en la tabla",
                    paginate: {
                        first: "Primero",
                        previous: "Anterior",
                        next: "Siguiente",
                        last: "Último"
                    },
                    aria: {
                        sortAscending: ": Activar para ordenar de manera ascendente",
                        sortDescending: ": Activar para ordenar de manera descendente"
                    }
                }
            });
        } else {
            // Si no hay archivos, mostrar un mensaje de "Sin actividad"
            $("#miTabla").DataTable({
                destroy: true, // Para evitar inicializaciones duplicadas
                paging: true,
                searching: true,
                ordering: true,
                info: true,
                autoWidth: false,
                responsive: true,
                data: [{
                    ID_Registro: "N/A", // Agregamos una propiedad con valor predeterminado
                    Nombre_Archivo: 'Sin actividad',
                    Tipo_Archivo: 'N/A',
                    Fecha_Carga: 'N/A',
                    Accion: 'N/A'
                }], // Colocamos un solo registro con 'Sin actividad'
                columns: [
                    { title: "ID", data: "ID_Registro" },
                    { title: "Nombre archivo", data: "Nombre_Archivo" },
                    { title: "Tipo Documento", data: "Tipo_Archivo" },
                    { title: "Fecha Carga", data: "Fecha_Carga" },
                    { title: "Acción", data: "Accion" }
                ],
                language: {
                    search: "Buscar:",
                    lengthMenu: "Mostrar _MENU_ registros",
                    info: "Mostrando _START_ a _END_ de _TOTAL_ registros",
                    infoEmpty: "No hay registros disponibles",
                    infoFiltered: "(filtrado de _MAX_ registros totales)",
                    loadingRecords: "Cargando...",
                    zeroRecords: "No se encontraron registros",
                    emptyTable: "No hay datos disponibles en la tabla",
                    paginate: {
                        first: "Primero",
                        previous: "Anterior",
                        next: "Siguiente",
                        last: "Último"
                    },
                    aria: {
                        sortAscending: ": Activar para ordenar de manera ascendente",
                        sortDescending: ": Activar para ordenar de manera descendente"
                    }
                }
            });
        }
    }, [archivosDB]); // Solo se ejecuta cuando archivosDB cambia
    

    return (
        <body id="page-top">
            <div id="wrapper">
                <Sidebar
                    imageUrl={imageUrl}
                    isComponentsCollapsed={isComponentsCollapsed}
                    toggleCollapse={toggleCollapse}
                    setIsComponentsCollapsed={setIsComponentsCollapsed}
                    isUtilitiesCollapsed={isUtilitiesCollapsed}
                    setIsUtilitiesCollapsed={setIsUtilitiesCollapsed}
                    isInventario={isInventario}
                    setisIventatioCollapsed={setisIventatioCollapsed}
                    isPrevencion={isPrevencion}
                    setisPrevencionCollapsed={setisPrevencionCollapsed}
                    isLogistica={isLogistica}
                    setisLogisticaCollapsed={setisLogisticaCollapsed}
                />
                <div id="content-wrapper" class="d-flex flex-column">
                    <div id="content">
                        <UserPage cookies={cookies} />
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            <h6 class="m-0 font-weight-bold text-primary">Historial de trabajadores de {empresa}</h6>
                                        </div>
                                        <div class="card-body">
                                            <div class="mb-6">
                                                <div class="input-group mb-3">
                                                    <span class="input-group-text" id="basic-addon1">Trabajador:</span>
                                                    <select className="form-select" value={rutSelected} onChange={handleSelectChange}>
                                                        <option value="">Seleccione un trabajador:</option>
                                                        {trabajadores.map(trabajador => (
                                                            <option key={trabajador.ID_Trabajador} value={trabajador.Rut}>
                                                                {trabajador.NombreCompleto}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span class="input-group-text" id="basic-addon1">Tipo documento</span>
                                                    <select className="form-control" name="TipoArchivo" value={tipoArchivo} onChange={(event) => { settipoArchivo(event.target.value); }}>
                                                        <option value="none">Seleccione:</option>
                                                        <option value="Contrato">Opcion 1</option>
                                                        <option value="Compensados">Opcion 2</option>
                                                        <option value="Compensados">Opcio 3</option>
                                                    </select>
                                                    <input type="file" className="form-control" onChange={onFileChange} />
                                                    <button className="btn btn-success" onClick={onFileUpload}>Subir</button>
                                                </div>
                                            </div>
                                            <div class="mb-6">
                                                <table id="miTabla" className="display">
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Nombre Archivo</th>
                                                            <th>Tipo Archivo</th>
                                                            <th>Fecha Carga</th>
                                                            <th>Acción</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Los datos serán llenados automáticamente por DataTable */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    );
}

export default Historialtrabajador

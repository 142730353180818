import React, { useState, useEffect } from "react"
import { useCookies } from 'react-cookie';
import Sidebar from '../SideBar';
import UserPage from "../UserPage";
import useAuthRedirect from "../Componentes/auth";
import Axios from 'axios';
import config from '../Componentes/config';
import Swal from 'sweetalert2';
import { registrarLog } from '../Componentes/logService';
import useCheckCookie from "../Componentes/cookieVal";
import { validarFormatos } from '../Validations/TrabajadoresValidation';
import $ from "jquery";
import moment from 'moment';

const cardImages = {
    DAFA: "/img/dafaLogo.png",
    FUGA: "/img/fugarLogo.png"
};

function Documentos() {
    useAuthRedirect();
    useCheckCookie();
    const [cookies] = useCookies(['selectedCard']);
    const [cookies2] = useCookies(['faenaActiva']);
    const imageUrl = cardImages[cookies.selectedCard]
    const [isComponentsCollapsed, setIsComponentsCollapsed] = useState(true);
    const [isUtilitiesCollapsed, setIsUtilitiesCollapsed] = useState(true);
    const [isInventario, setisIventatioCollapsed] = useState(true);
    const [isPrevencion, setisPrevencionCollapsed] = useState(true);
    const [isLogistica, setisLogisticaCollapsed] = useState(true);
    const toggleCollapse = (collapseSetter) => {
        collapseSetter(prevState => !prevState);
    };
    const [files, setFiles] = useState([]);
    const [archivosDB, setArchivosDB] = useState([]);
    const [nombreArchivo, setNombreArchivo] = useState("");
    const [version, setVersion] = useState("");
    const [tipoArchivo, settipoArchivo] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [listadoFormatos, setlistadoFormatos] = useState([]);
    const getEmpresaName = (card) => {
        switch (card) {
            case 'FUGA':
                return 'FUGAR SERVICIOS INDUSTRIALES SPA';
            case 'DAFA':
                return 'DAFA FACILITY SERVICES SPA';
            default:
                return ''; // Retorna un string vacío o un valor por defecto si no coincide
        }
    };
    // Estados para el componente Trabajadores
    const [empresa, setEmpresa] = useState(getEmpresaName(cookies.selectedCard));
    const onFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };
    const onFileUpload = async () => {
        const mensajeError = validarFormatos(nombreArchivo, version, tipoArchivo, selectedFile);

        if (mensajeError) {
            Swal.fire('Advertencia', mensajeError, 'warning');
            return; // Detiene la función si falla la validación
        }
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("filename", selectedFile.name);
        formData.append("idEmpresa", cookies.idEmpresa);
        formData.append("idFaena", cookies2.idFaena);
        formData.append("codigoFaena", cookies2.codigoFaena);
        formData.append("nombreArchivo", nombreArchivo);
        formData.append("version", version); // Suponiendo que 'rut' está disponible en el estado
        formData.append("tipoArchivo", tipoArchivo); // Suponiendo que 'rut' está disponible en el estado
        formData.append("submodulo", "Documentos");
        formData.append("UsuarioCarga", cookies.usuario); // Suponiendo que 'mail' contiene la fecha de carga
        try {
            const response = await Axios.post(`${config.API_URL}/SubeArchivosPrevencion/${cookies2.codigoFaena}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            Swal.fire(
                '¡Éxito!',
                'El archivo ha sido subido exitosamente.',
                'success'
            );
            fetchFiles();
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Prevención',
                Detalle: `Sube archivo formato: ${nombreArchivo} version:${version}`,
                Estado: 'Exito',
            });
        } catch (error) {
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Prevención',
                Detalle: `Sube archivo formato: ${nombreArchivo} version:${version}`,
                Estado: 'Error',
            });
            Swal.fire(
                '¡Algo pasó!',
                'El archivo no ha podido subirse.',
                'error'
            );
        }
    }

    const fetchFiles = async () => {
        const datos = {
            submodulo: "Documentos",
            Faena: cookies2.idFaena,
        };
        try {
            setFiles([]); // Limpiar archivos anteriores
            setArchivosDB([]); // Limpiar archivosDB anteriores

            const response = await Axios.get(`${config.API_URL}/CargaArchivosPrevencion`, {
                params: datos,
            });

            // Verifica que los datos sean correctos
            console.log("Respuesta de la API:", response.data);

            const archivosDirectorio = Array.isArray(response.data.archivosDirectorio) ? response.data.archivosDirectorio : [];
            const archivosDB = Array.isArray(response.data.archivosDB) ? response.data.archivosDB : [];

            // Verifica los datos antes de actualizar el estado
            console.log("ArchivosDB:", archivosDB);

            // Actualiza los estados
            setFiles(archivosDirectorio);
            setArchivosDB(archivosDB);
        } catch (error) {
            setFiles([]);
            setArchivosDB([]);
            console.error("Error fetching files", error);
        }
    };

    useEffect(() => {
        fetchFiles(); // Llamamos a la función al montar el componente
    }, []);

    useEffect(() => {
        if (archivosDB.length > 0) {
            $("#miTabla").DataTable({
                destroy: true, // Para evitar inicializaciones duplicadas
                paging: true,
                searching: true,
                ordering: true,
                info: true,
                autoWidth: false,
                responsive: true,
                data: archivosDB, // Pasamos los datos directamente
                columns: [
                    { title: "ID", data: "ID_Archivo" },
                    { title: "Nombre", data: "NombreArchivo" },
                    { title: "Versión", data: "Version" },
                    { title: "Tipo Archivo", data: "TipoArchivo" },
                    { 
                        title: "Fecha Carga",
                        data: "FechaCarga",
                        render: function (data) {
                            return moment(data).format("DD/MM/YYYY");
                        }
                    },
                    { title: "Usuario carga", data: "UsuarioCarga" },
                    { 
                        title: "Acción",
                        data: "Archivo",
                        render: function (data) {
                            return data ? `<a href="${config.API_URL}/uploads/prevencion/${cookies2.codigoFaena}/${encodeURIComponent(data)}" target="_blank" rel="noopener noreferrer">Descargar</a>` : "No disponible";
                        }
                    }
                ],
                language: {
                    search: "Buscar:",
                    lengthMenu: "Mostrar _MENU_ registros",
                    info: "Mostrando _START_ a _END_ de _TOTAL_ registros",
                    infoEmpty: "No hay registros disponibles",
                    infoFiltered: "(filtrado de _MAX_ registros totales)",
                    loadingRecords: "Cargando...",
                    zeroRecords: "No se encontraron registros",
                    emptyTable: "No hay datos disponibles en la tabla",
                    paginate: {
                        first: "Primero",
                        previous: "Anterior",
                        next: "Siguiente",
                        last: "Último"
                    },
                    aria: {
                        sortAscending: ": Activar para ordenar de manera ascendente",
                        sortDescending: ": Activar para ordenar de manera descendente"
                    }
                }
            });
        }
    }, [archivosDB]);
    return (
        <body id="page-top">
            <div id="wrapper">
                <Sidebar
                    imageUrl={imageUrl}
                    isComponentsCollapsed={isComponentsCollapsed}
                    toggleCollapse={toggleCollapse}
                    setIsComponentsCollapsed={setIsComponentsCollapsed}
                    isUtilitiesCollapsed={isUtilitiesCollapsed}
                    setIsUtilitiesCollapsed={setIsUtilitiesCollapsed}
                    isInventario={isInventario}
                    setisIventatioCollapsed={setisIventatioCollapsed}
                    isPrevencion={isPrevencion}
                    setisPrevencionCollapsed={setisPrevencionCollapsed}
                    isLogistica={isLogistica}
                    setisLogisticaCollapsed={setisLogisticaCollapsed}
                />
                <div id="content-wrapper" class="d-flex flex-column">
                    <div id="content">
                        <UserPage cookies={cookies} />
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            <h6 class="m-0 font-weight-bold text-primary">Listado de Documentos para {cookies2.faenaActiva} </h6>
                                        </div>
                                        <div class="card-body">
                                            <div class="mb-12">
                                                <div>
                                                    <div className="input-group mb-3">
                                                        <span class="input-group-text" id="basic-addon1">Nombre archivo</span>
                                                        <input type="text" class="form-control" value={nombreArchivo} onChange={(event) => { setNombreArchivo(event.target.value); }} />
                                                        <span class="input-group-text" id="basic-addon1">Versión</span>
                                                        <input type="text" class="form-control" value={version} onChange={(event) => { setVersion(event.target.value); }} />
                                                        <span class="input-group-text" id="basic-addon1">Tipo archivo:</span>
                                                        <select className="form-control" name="TipoArchivo" value={tipoArchivo} onChange={(event) => { settipoArchivo(event.target.value); }}>
                                                            <option value="none">Seleccione:</option>
                                                            <option value="Contrato">Opcion 1</option>
                                                            <option value="Compensados">Opcion 2</option>
                                                            <option value="Compensados">Opcion 3</option>
                                                        </select>
                                                        <input type="file" className="form-control" onChange={onFileChange} />
                                                    </div>
                                                    <button className="btn btn-success" onClick={onFileUpload}>Subir</button>
                                                </div>
                                                <table id="miTabla" className="display">
                                                    <thead>
                                                        <tr>
                                                            <th>ID Archivo</th>
                                                            <th>Nombre Archivo</th>
                                                            <th>Versión</th>
                                                            <th>Tipo Archivo</th>
                                                            <th>Fecha Carga</th>
                                                            <th>Usuario Carga</th>
                                                            <th>Acción</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Los datos serán llenados automáticamente por DataTable */}
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    )
}

export default Documentos

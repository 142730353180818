import React, { useState, useEffect } from "react"
import { useCookies } from 'react-cookie';
import Sidebar from './SideBar';
import UserPage from "./UserPage";
import useAuthRedirect from "./Componentes/auth";
import Axios from 'axios';
import config from './Componentes/config';
import Swal from 'sweetalert2';
import { registrarLog } from './Componentes/logService';

const cardImages = {
    DAFA: "/img/dafaLogo.png",
    FUGA: "/img/fugarLogo.png"
};



function Asistencia() {
    useAuthRedirect();
    const [cookies] = useCookies(['selectedCard']);
    const imageUrl = cardImages[cookies.selectedCard]
    const [isComponentsCollapsed, setIsComponentsCollapsed] = useState(true);
    const [isUtilitiesCollapsed, setIsUtilitiesCollapsed] = useState(true);
    const [isInventario, setisIventatioCollapsed] = useState(true);
    const [isPrevencion, setisPrevencionCollapsed] = useState(true);
    const [isLogistica, setisLogisticaCollapsed] = useState(true);
    const toggleCollapse = (collapseSetter) => {
        collapseSetter(prevState => !prevState);
    };
    const [trabajadores, setTrabajadores] = useState([]);
    const [idTrabajadorSeleccionado, setIdTrabajadorSeleccionado] = useState('');
    const [nombreTrabajadorSeleccionado, setNombreTrabajadorSeleccionado] = useState('');
    const [rutTrabajadorSeleccionado, setRutTrabajadorSeleccionado] = useState('');
    const idEmpresa = cookies.idEmpresa;
    const seleccionTrue = (ID) => {
        setSeleccion(true);
        console.log('ID:' + ID);
        // fetchFiles();
    }
    const [seleccion, setSeleccion] = useState(false);
    const getEmpresaName = (card) => {
        switch (card) {
            case 'FUGA':
                return 'FUGAR SERVICIOS INDUSTRIALES SPA';
            case 'DAFA':
                return 'DAFA FACILITY SERVICES SPA';
            default:
                return ''; // Retorna un string vacío o un valor por defecto si no coincide
        }
    };
    // Estados para el componente Trabajadores
    const [empresa, setEmpresa] = useState(getEmpresaName(cookies.selectedCard));

    const getTrabajadores = () => {
        console.log("idEmpresa:", idEmpresa);  // Verifica que idEmpresa tiene el valor esperado
        console.log("intentando cargar trabajadores compensado");
        Axios.get(`${config.API_URL}/CargaTrabajadores?id=${idEmpresa}`)
            .then(res => {
                console.log(res.data); // Verifica la estructura de la respuesta
                if (Array.isArray(res.data)) {
                    setTrabajadores(res.data); // Asegúrate de que sea un array
                } else {
                    console.error('La respuesta no es un array:', res.data);
                    setTrabajadores([]); // Establece un array vacío si no es un array
                }
            })
            .catch(err => {
                console.error("Error al obtener los datos:", err);
            });
    }



    useEffect(() => {
        getTrabajadores();
    }, []);

    return (
        <body id="page-top">
            <div id="wrapper">
                <Sidebar
                    imageUrl={imageUrl}
                    isComponentsCollapsed={isComponentsCollapsed}
                    toggleCollapse={toggleCollapse}
                    setIsComponentsCollapsed={setIsComponentsCollapsed}
                    isUtilitiesCollapsed={isUtilitiesCollapsed}
                    setIsUtilitiesCollapsed={setIsUtilitiesCollapsed}
                    isInventario={isInventario}
                    setisIventatioCollapsed={setisIventatioCollapsed}
                    isPrevencion={isPrevencion}
                    setisPrevencionCollapsed={setisPrevencionCollapsed}
                    isLogistica={isLogistica}
                    setisLogisticaCollapsed={setisLogisticaCollapsed}
                />
                <div id="content-wrapper" class="d-flex flex-column">
                    <div id="content">
                        <UserPage cookies={cookies} />
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            <h6 class="m-0 font-weight-bold text-primary">Módulo de Asistencia de {empresa}</h6>
                                        </div>
                                        <div class="card-body">
                                            <div class="input-group mb-3">
                                                <span class="input-group-text" id="basic-addon1">Trabajador:</span>
                                                <select class="form-select" value={idTrabajadorSeleccionado}
                                                    onChange={(e) => {
                                                        setIdTrabajadorSeleccionado(e.target.value);
                                                        seleccionTrue(e.target.value);
                                                        setNombreTrabajadorSeleccionado(e.target.options[e.target.selectedIndex].text);
                                                        setRutTrabajadorSeleccionado(e.target.options[e.target.selectedIndex].getAttribute('data-rut'));
                                                    }}>
                                                    <option value="">Seleccione un trabajador:</option>
                                                    {trabajadores.map(trabajador => (
                                                        <option
                                                            key={trabajador.ID_Trabajador}
                                                            value={trabajador.ID_Trabajador}
                                                            data-rut={trabajador.Rut}>
                                                            {trabajador.NombreCompleto}
                                                        </option>
                                                    ))}
                                                </select >
                                                <span class="input-group-text" id="basic-addon1">Fecha marca</span>
                                                <input type="datetime-local" className="form-control" />
                                                <span class="input-group-text" id="basic-addon1">Tipo marca</span>
                                                <select class="form-select">
                                                    <option>Seleccione:</option>
                                                    <option>Entrada dia</option>
                                                    <option>Saliente dia</option>
                                                    <option>Entrada noche</option>
                                                    <option>Saliente noche</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    )
}

export default Asistencia

import './ccss/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ccss/sb-admin-2.min.css';
import './ccss/fontawesome-free/css/all.min.css'
import "datatables.net-dt/css/dataTables.dataTables.min.css"; // Estilos correctos
import "datatables.net-dt"; // Importa DataTables
import Login from './Login';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Registro from './Registro'
import Home from './Home'
import Prehome from './Prehome'
import Trabajadores from './Trabajadores'
import Contratos from './Contratos'
import Compensados from './Compensados'
import Permisos from './Permisos';
import Cotizaciones from './Cotizaciones';
import CotizacionVenta from './CotizacionVenta';
import Vacaciones from './Vacaciones';
import Administracion from './Administracion';
import Procedimiento from "./prevencion/Procedimiento.js";
import Formatos from './prevencion/Formatos.js';
import Historialtrabajador from './prevencion/Historialtrabajador.js';
import Multimedia from './prevencion/Multimedia.js';
import Faenas from './prevencion/Faenas.js';
import RegistroPrevencion from './prevencion/RegistroPrevencion.js';
import Documentos from './prevencion/Documentos.js';
import Liquidaciones from './Liquidaciones.js';
import Asistencia from './Asistencia.js';

function App() {
  return (
   <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />}></Route>
        <Route path='/registro' element={<Registro />}></Route>
        <Route path='/home' element={<Home />}></Route>
        <Route path='/Prehome' element={<Prehome />}></Route>
        <Route path='/Trabajadores' element={<Trabajadores />}></Route>
        <Route path='/Contratos' element={<Contratos />}></Route>
        <Route path='/Login' element={<Login />}></Route>
        <Route path='/Compensados' element={<Compensados />}></Route>
        <Route path='/Permisos' element={<Permisos />}></Route>
        <Route path='/Cotizaciones' element={<Cotizaciones />}></Route>
        <Route path='/CotizacionVenta' element={<CotizacionVenta />}></Route>
        <Route path='/Vacaciones' element={<Vacaciones />}></Route>
        <Route path='/Administracion' element={<Administracion />}></Route>
        <Route path='/prevencion/Procedimiento' element={<Procedimiento />}></Route>
        <Route path='/prevencion/Formatos' element={<Formatos />}></Route>
        <Route path='/prevencion/Historialtrabajador' element={<Historialtrabajador />}></Route>
        <Route path='/prevencion/Multimedia' element={<Multimedia />}></Route>
        <Route path='/prevencion/Faenas' element={<Faenas />}></Route>
        <Route path='/prevencion/RegistroPrevencion' element={<RegistroPrevencion />}></Route>
        <Route path='/prevencion/Documentos' element={<Documentos />}></Route>
        <Route path='/Liquidaciones' element={<Liquidaciones />}></Route>
        <Route path='/Asistencia' element={<Asistencia />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
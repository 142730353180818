import React, { useState, useEffect } from "react"
import { useCookies } from 'react-cookie';
import Sidebar from '../SideBar';
import UserPage from "../UserPage";
import useAuthRedirect from "../Componentes/auth";
import Axios from 'axios';
import config from '../Componentes/config';
import Swal from 'sweetalert2';
import { registrarLog } from '../Componentes/logService';
import Calendar from 'react-calendar'; // Importa el componente Calendar de react-calendar
import 'react-calendar/dist/Calendar.css'; // Importa los estilos del calendario
import moment from 'moment';
import { validaBitacora } from '../Validations/TrabajadoresValidation';
import $ from "jquery";
import useCheckCookie from "../Componentes/cookieVal";

const cardImages = {
    DAFA: "/img/dafaLogo.png",
    FUGA: "/img/fugarLogo.png"
};


function RegistroPrevencion() {
    useAuthRedirect();
    useCheckCookie();
    const [cookies] = useCookies(['selectedCard']);
    const imageUrl = cardImages[cookies.selectedCard]
    const [cookies2] = useCookies(['faenaActiva']);
    const [isComponentsCollapsed, setIsComponentsCollapsed] = useState(true);
    const [isUtilitiesCollapsed, setIsUtilitiesCollapsed] = useState(true);
    const [isInventario, setisIventatioCollapsed] = useState(true);
    const [isPrevencion, setisPrevencionCollapsed] = useState(true);
    const [isLogistica, setisLogisticaCollapsed] = useState(true);
    const toggleCollapse = (collapseSetter) => {
        collapseSetter(prevState => !prevState);
    };
    const getEmpresaName = (card) => {
        switch (card) {
            case 'FUGA':
                return 'FUGAR SERVICIOS INDUSTRIALES SPA';
            case 'DAFA':
                return 'DAFA FACILITY SERVICES SPA';
            default:
                return ''; // Retorna un string vacío o un valor por defecto si no coincide
        }
    };
    // Estados para el componente Trabajadores
    const [empresa, setEmpresa] = useState(getEmpresaName(cookies.selectedCard));
    const [fechaSeleccionada, setFechaSeleccionada] = useState(new Date());
    const [nombreActividad, setNombreActividad] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [tipoActividad, settipoActividad] = useState("");
    const [files, setFiles] = useState([]);
    const [archivosDB, setArchivosDB] = useState([]);
    const onFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };
    const onFileUpload = async () => {
        const mensajeError = validaBitacora(nombreActividad, tipoActividad, selectedFile);

        if (mensajeError) {
            Swal.fire('Advertencia', mensajeError, 'warning');
            return; // Detiene la función si falla la validación
        }
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("filename", selectedFile.name);
        formData.append("idEmpresa", cookies.idEmpresa);
        formData.append("idFaena", cookies2.idFaena);
        formData.append("nombreArchivo", nombreActividad);
        formData.append("version", "1"); // Suponiendo que 'rut' está disponible en el estado
        formData.append("tipoArchivo", tipoActividad); // Suponiendo que 'rut' está disponible en el estado
        formData.append("submodulo", "Bitacora");
        formData.append("UsuarioCarga", cookies.usuario); // Suponiendo que 'mail' contiene la fecha de carga
        formData.append("FechaArchivo", moment(fechaSeleccionada).format("YYYY-MM-DD")); // Suponiendo que 'mail' contiene la fecha de carga
        try {
            const response = await Axios.post(`${config.API_URL}/SubeArchivosPrevencion/${cookies2.codigoFaena}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            Swal.fire(
                '¡Éxito!',
                'El archivo ha sido subido exitosamente.',
                'success'
            );
            fetchFiles();
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Prevención',
                Detalle: `Sube archivo bitacora: ${nombreActividad}`,
                Estado: 'Exito',
            });
        } catch (error) {
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Prevención',
                Detalle: `Sube archivo formato: ${nombreActividad}`,
                Estado: 'Error',
            });
            Swal.fire(
                '¡Algo pasó!',
                'El archivo no ha podido subirse.',
                'error'
            );
        }
    }

    const fetchFiles = async () => {
        const datos = {
            submodulo: "Bitacora",
            Faena: cookies2.idFaena,
            Fecha: moment(fechaSeleccionada).format("YYYY-MM-DD"),
        };
    
        try {
            setFiles([]); // Limpiar archivos anteriores
            setArchivosDB([]); // ✅ Vaciar la tabla antes de la nueva consulta
    
            const response = await Axios.get(`${config.API_URL}/CargaArchivosPrevencion`, {
                params: datos,
            });
    
            console.log("Respuesta de la API:", response.data);
    
            const archivosDirectorio = Array.isArray(response.data.archivosDirectorio) ? response.data.archivosDirectorio : [];
            const archivosDB = Array.isArray(response.data.archivosDB) ? response.data.archivosDB : [];
    
            console.log("ArchivosDB:", archivosDB);
    
            setFiles(archivosDirectorio);
            setArchivosDB(archivosDB);
        } catch (error) {
            setFiles([]);
            setArchivosDB([]); // ✅ Si hay error, aseguramos que la tabla se vacíe
            console.error("Error fetching files", error);
        }
    };

    useEffect(() => {
        setFechaSeleccionada(new Date());
    }, []);

    const handleFechaChange = (date) => {
        // Establecer la nueva fecha seleccionada
        setFechaSeleccionada(date);
    
        // Llamar a fetchFiles después de que la fecha se haya actualizado
        fetchFiles();
    };

    useEffect(() => {
        fetchFiles(); // Llamamos a la función al montar el componente
    }, []);

    useEffect(() => {
        // Si la tabla ya está inicializada, destrúyela antes de volver a llenarla
        if ($.fn.DataTable.isDataTable("#miTabla")) {
            $("#miTabla").DataTable().clear().destroy();
        }
    
        // Si `archivosDB` tiene datos, inicializa la tabla
        if (archivosDB.length > 0) {
            $("#miTabla").DataTable({
                destroy: true,
                paging: true,
                searching: true,
                ordering: true,
                info: true,
                autoWidth: false,
                responsive: true,
                data: archivosDB,
                columns: [
                    { title: "ID registro", data: "ID_Archivo" },
                    { title: "Nombre Actividad", data: "NombreArchivo" },
                    { title: "Tipo Actividad", data: "TipoArchivo" },
                    {
                        title: "Fecha Actividad",
                        data: "FechaCarga",
                        render: function (data) {
                            return moment(data).format("DD/MM/YYYY");
                        }
                    },
                    {
                        title: "Acción",
                        data: "Archivo",
                        render: function (data) {
                            return data
                                ? `<a href="${config.API_URL}/uploads/prevencion/${cookies2.codigoFaena}/${encodeURIComponent(data)}" target="_blank" rel="noopener noreferrer">Descargar</a>`
                                : "No disponible";
                        }
                    }
                ],
                language: {
                    search: "Buscar:",
                    lengthMenu: "Mostrar _MENU_ registros",
                    info: "Mostrando _START_ a _END_ de _TOTAL_ registros",
                    infoEmpty: "No hay registros disponibles",
                    infoFiltered: "(filtrado de _MAX_ registros totales)",
                    loadingRecords: "Cargando...",
                    zeroRecords: "No se encontraron registros",
                    emptyTable: "No hay datos disponibles en la tabla",
                    paginate: {
                        first: "Primero",
                        previous: "Anterior",
                        next: "Siguiente",
                        last: "Último"
                    },
                    aria: {
                        sortAscending: ": Activar para ordenar de manera ascendente",
                        sortDescending: ": Activar para ordenar de manera descendente"
                    }
                }
            });
        } else {
            // Si `archivosDB` está vacío, limpia la tabla manualmente
            $("#miTabla tbody").empty();
        }
    }, [archivosDB]);
    
    return (
        <body id="page-top">
            <div id="wrapper">
                <Sidebar
                    imageUrl={imageUrl}
                    isComponentsCollapsed={isComponentsCollapsed}
                    toggleCollapse={toggleCollapse}
                    setIsComponentsCollapsed={setIsComponentsCollapsed}
                    isUtilitiesCollapsed={isUtilitiesCollapsed}
                    setIsUtilitiesCollapsed={setIsUtilitiesCollapsed}
                    isInventario={isInventario}
                    setisIventatioCollapsed={setisIventatioCollapsed}
                    isPrevencion={isPrevencion}
                    setisPrevencionCollapsed={setisPrevencionCollapsed}
                    isLogistica={isLogistica}
                    setisLogisticaCollapsed={setisLogisticaCollapsed}
                />
                <div id="content-wrapper" class="d-flex flex-column">
                    <div id="content">
                        <UserPage cookies={cookies} />
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            <h6 class="m-0 font-weight-bold text-primary">Bitacora diaria para {cookies2.faenaActiva} </h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="input-group mb-3">
                                                <div className="row g-3 d-flex align-items-start">
                                                    {/* Calendario */}
                                                    <div className="col-md-3">
                                                        <Calendar
                                                            onChange={handleFechaChange}
                                                            value={fechaSeleccionada}
                                                            className="calendario-pequeno"
                                                        />
                                                    </div>

                                                    {/* Contenedor del formulario */}
                                                    <div className="col-md-9">
                                                        <div className="row g-3">
                                                            {/* Nombre Actividad */}
                                                            <div className="col-md-8">
                                                                <div className="input-group">
                                                                    <span className="input-group-text">Nombre Actividad</span>
                                                                    <input type="text" className="form-control" placeholder="Ingrese nombre de la actividad" value={nombreActividad} onChange={(event) => { setNombreActividad(event.target.value); }} />
                                                                </div>
                                                            </div>

                                                            {/* Tipo Actividad */}
                                                            <div className="col-md-4">
                                                                <div className="input-group">
                                                                    <span className="input-group-text">Tipo actividad</span>
                                                                    <select className="form-control" value={tipoActividad} onChange={(event) => { settipoActividad(event.target.value); }}>
                                                                        <option value="none">Seleccione:</option>
                                                                        <option value="Contrato">Opción 1</option>
                                                                        <option value="Compensados">Opción 2</option>
                                                                        <option value="Compensados">Opción 3</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            {/* Fecha Actividad */}
                                                            <div className="col-md-4">
                                                                <div className="input-group">
                                                                    <span className="input-group-text">Fecha actividad</span>
                                                                    <input type="text" className="form-control" disabled={true} value={moment(fechaSeleccionada).format("DD/MM/YYYY")} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input type="file" className="form-control" onChange={onFileChange} />
                                                            </div>
                                                            {/* Botón de Registrar */}
                                                            <div className="col-md-2 d-flex align-items-center">
                                                                <button className="btn btn-success w-100" onClick={onFileUpload}>Registrar</button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <table id="miTabla" className="display">
                                                            <thead>
                                                                <tr>
                                                                    <th>ID Archivo</th>
                                                                    <th>Nombre Archivo</th>
                                                                    <th>Tipo Archivo</th>
                                                                    <th>Fecha Carga</th>
                                                                    <th>Acción</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {/* Los datos serán llenados automáticamente por DataTable */}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    );
}

export default RegistroPrevencion

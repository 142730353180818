import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const useCheckCookie = () => {
    const [cookies2] = useCookies(["faenaActiva"]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!cookies2.faenaActiva) {
            navigate("../prevencion/Faenas"); // Reemplaza "/login" con la ruta deseada
        }
    }, [cookies2, navigate]);
};

export default useCheckCookie;

import React, { useState, useEffect } from "react"
import { useCookies } from 'react-cookie';
import Sidebar from '../SideBar';
import UserPage from "../UserPage";
import useAuthRedirect from "../Componentes/auth";
import Axios from 'axios';
import config from '../Componentes/config';
import Swal from 'sweetalert2';
import $ from "jquery";


const cardImages = {
    DAFA: "/img/dafaLogo.png",
    FUGA: "/img/fugarLogo.png"
};


function Multimedia() {
    const [cookies] = useCookies(['selectedCard']);
    const imageUrl = cardImages[cookies.selectedCard]
    const [isComponentsCollapsed, setIsComponentsCollapsed] = useState(true);
    const [isUtilitiesCollapsed, setIsUtilitiesCollapsed] = useState(true);
    const [isInventario, setisIventatioCollapsed] = useState(true);
    const [isPrevencion, setisPrevencionCollapsed] = useState(true);
    const [isLogistica, setisLogisticaCollapsed] = useState(true);
    const toggleCollapse = (collapseSetter) => {
        collapseSetter(prevState => !prevState);
    };
    const getEmpresaName = (card) => {
        switch (card) {
            case 'FUGA':
                return 'FUGAR SERVICIOS INDUSTRIALES SPA';
            case 'DAFA':
                return 'DAFA FACILITY SERVICES SPA';
            default:
                return ''; // Retorna un string vacío o un valor por defecto si no coincide
        }
    };

    const [videoUrl, setVideoUrl] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const abrirModal = (url) => {
        setVideoUrl(url);
        setModalOpen(true);
    };

    const cerrarModal = () => {
        setVideoUrl("");
        setModalOpen(false);
    };
    // Estados para el componente Trabajadores
    const [empresa, setEmpresa] = useState(getEmpresaName(cookies.selectedCard));
    useEffect(() => {
        $("#miTabla").DataTable({
            language: {
                search: "Buscar:",
                lengthMenu: "Mostrar _MENU_ registros",
                info: "Mostrando _START_ a _END_ de _TOTAL_ registros",
                infoEmpty: "No hay registros disponibles",
                infoFiltered: "(filtrado de _MAX_ registros totales)",
                loadingRecords: "Cargando...",
                zeroRecords: "No se encontraron registros",
                emptyTable: "No hay datos disponibles en la tabla",
                paginate: {
                    first: "Primero",
                    previous: "Anterior",
                    next: "Siguiente",
                    last: "Último"
                },
                aria: {
                    sortAscending: ": Activar para ordenar de manera ascendente",
                    sortDescending: ": Activar para ordenar de manera descendente"
                }
            }
        });
    }, []);

    return (
        <body id="page-top">
            <div id="wrapper">
                <Sidebar
                    imageUrl={imageUrl}
                    isComponentsCollapsed={isComponentsCollapsed}
                    toggleCollapse={toggleCollapse}
                    setIsComponentsCollapsed={setIsComponentsCollapsed}
                    isUtilitiesCollapsed={isUtilitiesCollapsed}
                    setIsUtilitiesCollapsed={setIsUtilitiesCollapsed}
                    isInventario={isInventario}
                    setisIventatioCollapsed={setisIventatioCollapsed}
                    isPrevencion={isPrevencion}
                    setisPrevencionCollapsed={setisPrevencionCollapsed}
                    isLogistica={isLogistica}
                    setisLogisticaCollapsed={setisLogisticaCollapsed}
                />
                <div id="content-wrapper" class="d-flex flex-column">
                    <div id="content">
                        <UserPage cookies={cookies} />
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            <h6 class="m-0 font-weight-bold text-primary">Capacitaciones de {empresa}</h6>
                                        </div>
                                        <div class="card-body">
                                            <div class="mb-12">
                                                <div>
                                                    <div className="input-group mb-3">
                                                        <span class="input-group-text" id="basic-addon1">Nombre </span>
                                                        <input type="text" class="form-control" />
                                                        <span class="input-group-text" id="basic-addon1">Versión</span>
                                                        <input type="text" class="form-control" />
                                                        <span class="input-group-text" id="basic-addon1">Tipo archivo:</span>
                                                        <select className="form-control" name="TipoArchivo">
                                                            <option value="none">Seleccione:</option>
                                                            <option value="Contrato">Opcion 1</option>
                                                            <option value="Compensados">Opcion 2</option>
                                                            <option value="Compensados">Opcio 3</option>
                                                        </select>
                                                        <input type="file" className="form-control" />
                                                    </div>
                                                    <button className="btn btn-success">Subir</button>
                                                </div>
                                                <table id="miTabla" class="display">
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Nombre documento</th>
                                                            <th>Versión</th>
                                                            <th>Fecha</th>
                                                            <th>Ver Video</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Procedimiento 1</td>
                                                            <td>1</td>
                                                            <td>22/10/2025</td>
                                                            <td>
                                                                <button onClick={() => abrirModal("https://www.youtube.com/embed/VIDEO_ID")}>
                                                                    Ver Video
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Procedimiento 2</td>
                                                            <td>2</td>
                                                            <td>23/10/2025</td>
                                                            <td>
                                                                <button onClick={() => abrirModal("https://www.youtube.com/embed/VIDEO_ID")}>
                                                                    Ver Video
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                {/* Modal */}
                                                {modalOpen && (
                                                    <div className="modal">
                                                        <div className="modal-content">
                                                            <span className="close" onClick={cerrarModal}>
                                                                &times;
                                                            </span>
                                                            <iframe
                                                                width="100%"
                                                                height="315"
                                                                src={videoUrl}
                                                                frameBorder="0"
                                                                allowFullScreen
                                                            ></iframe>
                                                        </div>
                                                    </div>
                                                )}

                                                {/* Estilos */}
                                                <style>
                                                    {`
          .modal {
            display: flex;
            position: fixed;
            z-index: 1000;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            justify-content: center;
            align-items: center;
          }
          .modal-content {
            background: white;
            padding: 20px;
            border-radius: 8px;
            position: relative;
            width: 80%;
            max-width: 600px;
          }
          .close {
            position: absolute;
            top: 10px;
            right: 15px;
            font-size: 20px;
            cursor: pointer;
          }
        `}
                                                </style>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    );
}

export default Multimedia
